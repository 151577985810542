.hidden {
  display: none;
}

/*
[required] {
  border: 1px solid #f00;
}
*/

img {
  max-width: 100%;
  height: auto;
}

.product-img {
  max-width: 150px;
  max-height: 150px;
}

.reports {
  img {
    max-width: 50px;
    max-height: 50px;
  }
}

// jquery-ui autocomplete
.ui-autocomplete {
  img {
    max-width: 70px;
    max-height: 70px;
  }
}

.modal-dialog{
  max-width: 610px;
}

@media print {
  .noprint{
    display: none;
  }
}